import React from 'react';
import { Link} from 'react-router-dom';
import { Col, Row, Button } from 'antd';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/77.gif';

const style = {
    
    padding: '8px 0',
  };
  function clickMe1() {
    alert("You clicked TREINOVA LEARNING!");
  }

  function clickMe2() {
    alert("You clicked TREINOVA LEARNING!");
  }
  
const Hero = () => {

    
    return (
        <>
            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>
                
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
                <br />
                <br />
                    <div id='hero' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                        <br/>
                        <br />
                        <br />
                        
                            <h3 className="mb-5 md:text-4xl text-3xl font-bold text-blue-900" style={{}}>
                            {/* We build digital solutions to help businesses scale */}
                            Experience the freedom and flexibility of digital learning, designed to fit your unique lifestyle and learning pace.
                            </h3>
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">Transform your educational experience and step into the future with digital learning that prepares you for success!</div>
                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">

                            {/* <link rel="icon" href="favicon.ico" /> */}
                            <br/>
                            
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">Please choose your track - LEARNING to access learning content, or LAB for application of learning through challenges.</div>
                           
                           

 <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col className="gutter-row" span={4}>
        <div style={style}></div>
      </Col>
      <Col className="gutter-row" span={8}>
        <div style={style}> 
        <button type="submit" id="" className="uppercase text-3xl font-bold tracking-wide   hover:bg-gray-500 bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                            focus:outline-none focus:shadow-outline">
                                                <a href="https://dreamitdoit.tech/treinovalearning" target="_blank">
                                        Treinova Learning </a>
                                    </button>
        </div>
      </Col>
      <Col className="gutter-row" span={7}>
        <div style={style}>   
        <button type="submit" id="" className="uppercase text-3xl font-bold tracking-wide hover:bg-gray-500 bg-pink-500 text-gray-100 p-3 rounded-lg w-full 
                                        focus:outline-none focus:shadow-outline">
                                            <a href="https://dreamitdoit.tech/treinovalab" target="_blank">
                                    Treinova LAB </a>
                                </button>
        </div>
      </Col>
      {/* <Col className="gutter-row" span={4}>
        <div style={style}></div>
      </Col> */}
    </Row>
    </div>                        {/* <div >
                            
                         
                                    <button type="submit" id="" className="uppercase text-sm font-bold tracking-wide   hover:bg-gray-500 bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                            focus:outline-none focus:shadow-outline">
                                                <a href="https://dreamitdoit.tech/treinovalearning" target="_blank">
                                        Treinova Learning </a>
                                    </button>
                          
                                <button type="submit" id="" className="uppercase text-sm font-bold tracking-wide hover:bg-gray-500 bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                        focus:outline-none focus:shadow-outline">
                                            <a href="https://dreamitdoit.tech/treinovalab" target="_blank">
                                    Treinova LAB </a>
                                </button>
                            
                          
                            </div> */}
                        </div>
                        
                        
                        <br/>
                        <br/>
                        <br/>
                        <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
                            <img alt="card img" className="rounded-t float-right duration-1000" src={heroImg} />
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    
                </div>
                <br/>
                <br/>
                <br/>

            </div>
            <br />
                        <br />
            
        </>
    )
}

export default Hero;